<template>
  <div class="view content-padding">
    <TableOverview
      kind="monetizationBundle"
      :page-title="labels.monetization_bundles + ` ` + labels.overview"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getMBList"
    />
    <router-view
      name="create_monetization_bundle"
      modal-id="create-monetization-bundle"
      kind="monetizationBundle"
      :route-back="{ name: 'monetization-bundle-overview' }"
      :title="labels.create_new_monetization_bundle"
      :title-placeholder="labels.title"
      :text-placeholder="labels.description"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'description']"
      :custom-fields="mBundleCustomFields"
      @entity-created="monetizationBundleCreated"
    />
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
const colors = require("/colors.config.json");
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "Overview",
  components: {
    TableOverview,
  },
  computed: {
    ...mapState("helper", {
      admin_roles: "admin_roles",
    }),
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      if (this.hasRole("super") || this.hasRole("creator"))
        return [
          {
            label: "Create new",
            variant: "btn-purple",
            action: () => {
              if (this.$route.name !== "create-monetization-bundle") {
                this.$router.push({
                  name: "create-monetization-bundle",
                  query: {
                    page: this.$route.query.page,
                  },
                });
              }
            },
          },
        ];

      return [];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.name,
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 30%",
            whiteSpace: "nowrap",
          },
          {
            headerLabel: this.labels.status,
            headerColor: `${colors.gray_text}`,
            key: "status",
            computeFn: (input) => input,
            bodyColor: `${colors.gray_text_2}`,
            columnWidth: "1 1 60%",
            // textAlign: 'center',
          },
        ],
        sortable: false,
        // sortStyles: { flex: '0 0 5%', color: `${colors.gray_text}` },
        minTableWidth: "300px",
        actionsWidth: "0 0 10%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.open,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (data) =>
              this.$router.push({
                name: "mb-settings-basic",
                params: { id: data._id },
              }),
          },
        ],
      };
    },
    mBundleCustomFields() {
      return [
        {
          type: "checkbox",
          name: "add_to_menu",
          label: this.labels.add_to_menu,
          class: "mt-4",
          required: false
        }
      ];
    }
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("monetizationBundle", {
      getMonetizationBundleList: "getMonetizationBundleList",
    }),
    monetizationBundleCreated() {
      this.getMBList(this.$route.query.page);
    },
    getMBList(page) {
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getMonetizationBundleList(data)
        .then((res) => {
          if (res.success) {
            this.setTablePagination(res.data.pages);
            if (
              res.data?.pages?.current && Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    hasRole(role) {
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes(role))
        return true;

      return false;
    },
  },
  mounted() {
    this.getMBList();
  },
};
</script>

<style lang="scss" scoped></style>
